<template>
  <div class="container-pc Global-W" v-loading="!isSubmitPayPal" element-loading-text="Payment in progress">
    <div class="order-pc-title">Confirm Order</div>

    <div class="order-pc-address">
      <div class="order-title">
        <div>Recipient Information</div>
        <div class="CURSOR" @click="handleisShowOrderAddress('Add')">Add New Address</div>
      </div>
      <div class="order-pc-address-view">
        <div class="order-address-item" v-for="(addItem, index) in AddressList" :key="index">
          <!-- left -->
          <div class="order-address-left">
            <div class="order-address-checkbox">
              <van-checkbox shape="square" icon-size="14px" checked-color="#414141" v-model="addItem.checked"
                @click="handleCheckboxAddress(addItem)"></van-checkbox>
            </div>

            <div>
              <div class="">{{ addItem.receiver }}</div>
              <div>{{ addItem.street }}</div>
              <div>{{ addItem.detail }}</div>
              <div class="">{{ addItem.city }} {{ addItem.state }} {{ addItem.postcode }}</div>
            </div>
          </div>

          <!-- right -->
          <div class="f-w CURSOR" @click="handleisShowOrderAddress('Edit', addItem)">Edit</div>
        </div>
        <div class="order-pc-address-view-tips" v-if="AddressList && AddressList.length <= 0">Add New Address</div>
      </div>

    </div>
    <div class="order-pc-status">

      <div class="order-title">Delivery and payment method</div>
      <div class="order-view">
        <div>Payment method: Paypal</div>
        <div>Delivery method: Express delivery (Time limit: 10 working days)</div>
        <div v-if="freight">Delivery fee: US${{ freight }}</div>
      </div>
    </div>

    <div class="order-pc-goods">
      <div class="order-title">Product list</div>
      <div class="order-view">
        <div class="order-good-item" v-for="(item, index) in Shopping" :key="index">

          <div class="order-good-item-img">
            <img :src="item.proImg" alt="">
          </div>
          <div class="order-good-item-info">
            <div class="order-info-item-dec">{{ item.proSeries }}</div>
            <div class="order-info-item-name">{{ item.proName }}</div>
            <div class="order-info-item-num">Quantity：{{ item.proNum }}</div>
            <div class="order-info-item-price">${{ item.proPrice }}</div>
          </div>

        </div>

      </div>
    </div>
    <div class="order-pc-price">
      <div class="order-price-left">
        <div>Total</div>
        <div>Subtotal ${{ totalPrice }} + Shipping ${{ freight }} = Total：${{ totalPriceFreight }}</div>
      </div>
      <van-button class="carts-bottom-btn" @click="handleSubmitOrder" :loading="!isSubmitPayPal"
        loading-text="Payment in progress..." size="large" color="#f1f1f1" type="default">
        <span class="t-icon t-icon-Paypal PAYAPL"></span>
      </van-button>
    </div>
  </div>

  <!-- <div class="container-m">
    <div class="order-m-title">确认订单</div>

    <div class="order-m-address"></div>
    <div class="order-m-status"></div>

    <div class="order-m-goods"></div>
    <div class="order-m-price"></div>

  </div> -->
  <OrderAddress v-if="isShowOrderAddress" :info="EditAddressInfo" @change="handleisShowOrderAddress" />
</template>

<script setup name="Order">
import { ref, onMounted } from 'vue'
import Cookies from 'js-cookie'
import requestAsync from "@/utils/request";
import { ElMessage } from 'element-plus'
import OrderAddress from '@/components/common/OrderAddress'
import { useRouter } from 'vue-router'
const router = useRouter()
const Shopping = ref([])
const totalPrice = ref(0)
const freight = ref(0) // 运费
const totalPriceFreight = ref(0)

const AddressList = ref([])
const isSubmitPayPal = ref(true)
const isShowOrderAddress = ref(false)
const EditAddressInfo = ref({})

onMounted(async () => {
  Shopping.value = JSON.parse(Cookies.get('shopping') || '[]')
  console.log('Shopping.value', Shopping.value)
  GetAddress()
  totalPriceChange()
  await getOrderFreight()
  totalPriceChange()

})

const GetAddress = async () => {
  const data = await requestAsync({
    url: '/userAddressinfo/address',
  })
  if (data.code == 200) {
    AddressList.value = data.data
  }
}

const totalPriceChange = () => {
  totalPrice.value = 0
  totalPriceFreight.value = 0
  Shopping.value.forEach(item => {
    totalPrice.value += item.proPrice * item.proNum;
  })
  totalPrice.value = totalPrice.value
  totalPriceFreight.value = (totalPrice.value + freight.value).toFixed(0)
}

const handleSubmitOrder = async () => {
  if (!Cookies.get('uid')) {
    router.replace({
      name: 'Login',
      query: { redirect: route.fullPath }
    })
    return
  }


  let addId = 0
  AddressList.value.forEach((addItem) => {
    if (addItem.checked) {
      addId = addItem.id
    }
  })

  if (addId <= 0) {
    ElMessage.error('Please select the address')
    return
  }


  if (!isSubmitPayPal.value) return
  isSubmitPayPal.value = false



  const data = await requestAsync({
    url: "/foreign/createOrder",
    methods: 'post',
    data: {
      userId: Cookies.get('uid'),
      siteCode: "en_SG",
      source: "1",
      addId,
      freight: freight.value,
      proList: Shopping.value
    },
  });

  console.log('handleSubmitOrder', data)
  if (data.code == 200) {
    SubmitPayPal(data.data)
    handleRemoveCart(Shopping.value)
  }

}

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
const handleRemoveCart = (shopping) => {
  const carts = JSON.parse(Cookies.get('shopCart') || '[]')
  shopping.forEach((item, index) => {
    carts.forEach((items, itemsIndex) => {
      if (item.proCode == items.proCode) {
        carts.splice(itemsIndex, 1)
      }
    })
  })
  Cookies.set('shopCart', JSON.stringify(carts))
}

const getOrderFreight = async () => {
  const data = await requestAsync({
    url: "/foreign/getOrderFreight",
    data: {
      orderPrice: totalPrice.value,
      currency: 'USD'
    }
  });
  if (data.code == 200) {
    freight.value = data.data[0].freight
  }
}


const SubmitPayPal = async (data) => {
  const SubmitPayPal = await requestAsync({
    url: "/pay/payment",
    methods: 'post',
    data: data
  });
  console.log('SubmitPayPal', SubmitPayPal)
  if (SubmitPayPal.code == 200) {
    ElMessage({
      showClose: true,
      message: 'Redirecting...',
      type: 'success',
    })
    window.location.href = SubmitPayPal.msg
  } else {
    ElMessage.error(SubmitPayPal.msg)
  }
  isSubmitPayPal.value = true
}


const handleisShowOrderAddress = (event, item) => {
  isShowOrderAddress.value = !isShowOrderAddress.value
  if (event == 1) {
    GetAddress()
  }
  if (event == 'Edit') {
    EditAddressInfo.value = item
  }
  if (event == 'Add') {
    EditAddressInfo.value = {}
  }
}

const handleCheckboxAddress = (addItem) => {
  AddressList.value.forEach((item) => {
    item.checked = false
  })
  addItem.checked = true
}

</script>

<style lang="scss" scoped>
.order-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #f1f1f1;
  padding: 0 30px;
  font-size: 14px;
}

.order-view {
  width: 100%;
  padding: 10px 30px;
  font-size: 14px;

  div {
    margin: 10px 0;
  }
}

.container-pc {
  .order-pc-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
  }

  .order-pc-address {
    .order-pc-address-view {
      width: 100%;
      padding: 10px 30px;
      font-size: 14px;

      .order-pc-address-view-tips {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }

      .order-address-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        .order-address-left {
          flex: 1 1;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .order-address-checkbox {
          width: 10%;
        }

        .order-address-address {
          width: 30%;
        }
      }
    }
  }

  .order-pc-status {}

  .order-pc-goods {
    .order-good-item {
      display: flex;
      border-bottom: 1px solid #f1f1f1;

      .order-good-item-img {
        width: 100px;
        height: 100px;
      }

      .order-good-item-info {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;

        .order-info-item-dec,
        .order-info-item-num {
          color: grey;
          font-size: 12px;
        }

        div {
          margin: 0;
        }
      }
    }
  }

  .order-pc-price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    font-size: 14px;
    margin-bottom: 40px;

    .order-price-left {
      flex: 1 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f1f1f1;
      margin-right: 20px;
      padding: 0 30px;
    }

    .carts-bottom-btn {
      width: 192px;
      height: 100%;
      background-color: #f1f1f1;
    }
  }
}
</style>